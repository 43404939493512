import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import axios from '../utils/axios';
import Swal from 'sweetalert2';

function Proveedores() {
  const [proveedores, setProveedores] = useState([]);
  const [formasPago, setFormasPago] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    nombre: '',
    formaPagoDefecto: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    cargarDatos();
  }, []);

  const cargarDatos = async () => {
    try {
      const [proveedoresRes, formasPagoRes] = await Promise.all([
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/proveedores`),
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/fdepagos`)
      ]);
      setProveedores(proveedoresRes.data);
      setFormasPago(formasPagoRes.data);
    } catch (error) {
      Swal.fire('Error', 'Error al cargar los datos', 'error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/proveedores/${formData.id}`, formData);
        Swal.fire('¡Éxito!', 'Proveedor actualizado', 'success');
      } else {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/proveedores`, formData);
        Swal.fire('¡Éxito!', 'Proveedor creado', 'success');
      }
      setShowModal(false);
      cargarDatos();
    } catch (error) {
      Swal.fire('Error', error.response?.data?.message || 'Error al guardar', 'error');
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "Esta acción no se puede deshacer",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/proveedores/${id}`);
        Swal.fire('Eliminado', 'El proveedor ha sido eliminado', 'success');
        cargarDatos();
      } catch (error) {
        Swal.fire('Error', 'No se pudo eliminar el proveedor', 'error');
      }
    }
  };

  const MobileCard = ({ proveedor }) => (
    <div className="card mb-3 shadow-sm">
      <div className="card-header">
        <h6 className="mb-0">{proveedor.nombre}</h6>
      </div>
      <div className="card-body">
        <div className="mb-2">
          <strong>Forma de Pago por Defecto:</strong>
          <div className="mt-1">
            {formasPago.find(f => f.id === proveedor.formaPagoDefecto)?.nombre || 'No especificada'}
          </div>
        </div>
      </div>
      <div className="card-footer d-flex justify-content-end gap-2">
        <button
          className="btn btn-warning btn-sm"
          onClick={() => {
            setIsEditing(true);
            setFormData(proveedor);
            setShowModal(true);
          }}
        >
          Editar
        </button>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => handleDelete(proveedor.id)}
        >
          Eliminar
        </button>
      </div>
    </div>
  );

  return (
    <div className="container mt-4">
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-4">
        <h2 className="mb-3 mb-md-0">Proveedores</h2>
        <button 
          className="btn btn-primary w-100 w-md-auto"
          onClick={() => {
            setIsEditing(false);
            setFormData({
              nombre: '',
              formaPagoDefecto: ''
            });
            setShowModal(true);
          }}
        >
          Nuevo Proveedor
        </button>
      </div>

      {isMobile ? (
        <div className="px-2">
          {proveedores.map(proveedor => (
            <MobileCard key={proveedor.id} proveedor={proveedor} />
          ))}
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Forma de Pago por Defecto</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {proveedores.map(proveedor => (
                <tr key={proveedor.id}>
                  <td>{proveedor.nombre}</td>
                  <td>
                    {formasPago.find(f => f.id === proveedor.formaPagoDefecto)?.nombre || 'No especificada'}
                  </td>
                  <td>
                    <button
                      className="btn btn-warning btn-sm me-2"
                      onClick={() => {
                        setIsEditing(true);
                        setFormData(proveedor);
                        setShowModal(true);
                      }}
                    >
                      Editar
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDelete(proveedor.id)}
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showModal && (
        <div className="modal fade show d-block" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {isEditing ? 'Editar' : 'Nuevo'} Proveedor
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <form onSubmit={handleSubmit} className="needs-validation">
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nombre"
                      value={formData.nombre}
                      onChange={(e) => setFormData({...formData, nombre: e.target.value})}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Forma de Pago por Defecto</label>
                    <select
                      className="form-select"
                      name="formaPagoDefecto"
                      value={formData.formaPagoDefecto}
                      onChange={(e) => setFormData({...formData, formaPagoDefecto: e.target.value})}
                      required
                    >
                      <option value="">Seleccione forma de pago</option>
                      {formasPago.map(forma => (
                        <option key={forma.id} value={forma.id}>
                          {forma.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Guardar
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Proveedores;
