import React, { useState, useEffect } from 'react';
import axios from '../utils/axios';
import Swal from 'sweetalert2';

function Usuarios() {
  const [usuarios, setUsuarios] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    nombre: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const isAdmin = currentUser?.username === 'admin';

  // Cargar usuarios solo una vez al montar el componente
  useEffect(() => {
    const cargarUsuarios = async () => {
      try {
        if (isAdmin) {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/usuarios`);
          setUsuarios(response.data);
        } else {
          setUsuarios([currentUser]);
        }
      } catch (error) {
        Swal.fire('Error', 'No se pudieron cargar los usuarios', 'error');
      }
    };

    cargarUsuarios();
  }, []); // Array de dependencias vacío

  // Manejar cambios en el formulario
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Abrir modal para nuevo usuario (solo admin)
  const handleNewUser = () => {
    if (!isAdmin) return;
    setFormData({
      username: '',
      password: '',
      nombre: ''
    });
    setIsEditing(false);
    setShowModal(true);
  };

  // Abrir modal para cambiar contraseña
  const handleChangePassword = (usuario) => {
    if (!isAdmin && usuario.id !== currentUser.id) return;
    setFormData({
      ...usuario,
      password: ''
    });
    setIsEditing(true);
    setShowModal(true);
  };

  // Guardar usuario
  const handleSave = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        if (!isAdmin && formData.id !== currentUser.id) {
          throw new Error('No tienes permisos para esta acción');
        }
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/usuarios/${formData.id}`, formData);
        Swal.fire('¡Éxito!', 'Contraseña actualizada correctamente', 'success');
      } else {
        if (!isAdmin) {
          throw new Error('No tienes permisos para esta acción');
        }
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/usuarios`, formData);
        Swal.fire('¡Éxito!', 'Usuario creado correctamente', 'success');
      }
      setShowModal(false);
      
      // Recargar usuarios después de una actualización
      if (isAdmin) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/usuarios`);
        setUsuarios(response.data);
      } else {
        setUsuarios([currentUser]);
      }
    } catch (error) {
      Swal.fire('Error', error.response?.data?.message || error.message, 'error');
    }
  };

  // Eliminar usuario (solo admin)
  const handleDelete = async (usuario) => {
    if (!isAdmin) return;
    if (usuario.id === currentUser.id) {
      Swal.fire('Error', 'No puedes eliminar tu propio usuario', 'error');
      return;
    }

    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "Se eliminarán todas las facturas asociadas a este usuario",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/usuarios/${usuario.id}`);
        Swal.fire('¡Eliminado!', 'Usuario eliminado correctamente', 'success');
        
        // Recargar usuarios después de eliminar
        if (isAdmin) {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/usuarios`);
          setUsuarios(response.data);
        } else {
          setUsuarios([currentUser]);
        }
      } catch (error) {
        Swal.fire('Error', 'No se pudo eliminar el usuario', 'error');
      }
    }
  };

  return (
    <div className="container col-4 mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Gestión de {isAdmin ? 'Usuarios' : 'Usuario'}</h2>
        {isAdmin && (
          <button className="btn btn-primary" onClick={handleNewUser}>
            Nuevo Usuario
          </button>
        )}
      </div>

      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Nombre</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {usuarios.map(usuario => (
              <tr key={usuario.id}>
                <td>{usuario.username}</td>
                <td>{usuario.nombre}</td>
                <td>
                  <button
                    className="btn btn-warning btn-sm me-2"
                    onClick={() => handleChangePassword(usuario)}
                  >
                    Cambiar Contraseña
                  </button>
                  {isAdmin && usuario.id !== currentUser.id && (
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDelete(usuario)}
                    >
                      Eliminar
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal para crear/editar usuario */}
      {showModal && (
        <div className="modal fade show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {isEditing ? 'Cambiar Contraseña' : 'Nuevo Usuario'}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <form onSubmit={handleSave}>
                <div className="modal-body">
                  {!isEditing && (
                    <>
                      <div className="mb-3">
                        <label className="form-label">Usuario</label>
                        <input
                          type="text"
                          className="form-control"
                          name="username"
                          value={formData.username}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Nombre</label>
                        <input
                          type="text"
                          className="form-control"
                          name="nombre"
                          value={formData.nombre}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </>
                  )}
                  <div className="mb-3">
                    <label className="form-label">Contraseña</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Guardar
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Usuarios;