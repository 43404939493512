import React, { useState } from 'react';
import axios from '../utils/axios';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Swal from 'sweetalert2';

function Login() {
  const [isRegistering, setIsRegistering] = useState(false);
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
    nombre: ''
  });
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isRegistering) {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/usuarios`, credentials);
        Swal.fire({
          icon: 'success',
          title: '¡Usuario creado!',
          text: 'Ahora puedes iniciar sesión',
          showConfirmButton: false,
          timer: 1500
        });
        setIsRegistering(false);
        setCredentials({ username: '', password: '', nombre: '' });
      } else {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login`, credentials);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        navigate('/');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'Error en la operación'
      });
    }
  };

  const toggleMode = () => {
    setIsRegistering(!isRegistering);
    setCredentials({ username: '', password: '', nombre: '' });
  };

  return (
    <div className="container-fluid vh-100 p-0">
      <div className="row h-100 g-0">
        {/* Lado izquierdo - Imagen */}
        <div className={`col-md-6 ${isMobile ? 'd-none' : 'd-flex'}`}>
          <div 
            className="h-100 w-100" 
            style={{
              backgroundImage: 'url("https://images.unsplash.com/photo-1520607162513-77705c0f0d4a")',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <div className="h-100 w-100 d-flex align-items-center justify-content-center" 
                 style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
              <div className="text-center text-white p-5">
                <h1 className="display-4 fw-bold mb-4">APPVenci</h1>
                <p className="lead">Gestiona tus facturas de manera eficiente</p>
              </div>
            </div>
          </div>
        </div>

        {/* Lado derecho - Formulario */}
        <div className={`col-12 col-md-6 d-flex align-items-center justify-content-center ${isMobile ? 'min-vh-100 bg-light' : ''}`}>
          <div className={`${isMobile ? 'w-90' : 'w-75'} py-4`}>
            <div className="card border-0 shadow-lg">
              <div className="card-body p-4 p-md-5">
                {isMobile && (
                  <div className="text-center mb-4">
                    <h1 className="h2 fw-bold text-primary">APPVenci</h1>
                    <p className="text-muted">Gestiona tus facturas de manera eficiente</p>
                  </div>
                )}
                
                <h2 className="text-center mb-4">
                  {isRegistering ? 'Crear cuenta' : 'Bienvenido'}
                </h2>
                
                <form onSubmit={handleSubmit}>
                  {isRegistering && (
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="nombre"
                        name="nombre"
                        placeholder="Nombre completo"
                        value={credentials.nombre}
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="nombre">Nombre completo</label>
                    </div>
                  )}

                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      name="username"
                      placeholder="Usuario"
                      value={credentials.username}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="username">Usuario</label>
                  </div>

                  <div className="form-floating mb-4">
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="Contraseña"
                      value={credentials.password}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="password">Contraseña</label>
                  </div>

                  <button 
                    type="submit" 
                    className="btn btn-primary w-100 py-3 mb-4"
                  >
                    {isRegistering ? 'Registrarse' : 'Iniciar Sesión'}
                  </button>

                  <button 
                    type="button" 
                    className="btn btn-link w-100 text-decoration-none"
                    onClick={toggleMode}
                  >
                    {isRegistering 
                      ? '¿Ya tienes cuenta? Inicia sesión' 
                      : '¿No tienes cuenta? Regístrate'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;