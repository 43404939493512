import React, { useState, useEffect, useCallback } from 'react';
import axios from '../utils/axios';
import Swal from 'sweetalert2';
import { useMediaQuery } from 'react-responsive';

function TableComponent() {
  const [facturas, setFacturas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    numeroFactura: '',
    proveedor: '',
    importe: '',
    fechaFactura: '',
    formaPago: '',
    vencimientos: []
  });
  const [proveedores, setProveedores] = useState([]);
  const [formasPago, setFormasPago] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [vencimientosEditables, setVencimientosEditables] = useState([]);
  const [mesFiltro, setMesFiltro] = useState(new Date().toISOString().slice(0, 7)); // YYYY-MM
  const [totalVencimientos, setTotalVencimientos] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const calcularTotalVencimientosPendientes = useCallback(() => {
    const total = facturas
      .flatMap(f => f.vencimientos || [])
      .filter(v => !v.pagado && v.fecha.startsWith(mesFiltro))
      .reduce((sum, v) => sum + (parseFloat(v.importe) || 0), 0);
    
    setTotalVencimientos(total);
  }, [facturas, mesFiltro]);

  useEffect(() => {
    cargarDatos();
  }, []);

  useEffect(() => {
    calcularTotalVencimientosPendientes();
  }, [calcularTotalVencimientosPendientes]);

  const filtrarYOrdenarVencimientos = (vencimientos, mesFiltro) => {
    if (!vencimientos || !Array.isArray(vencimientos)) return [];
    
    return vencimientos
      .filter(v => v.fecha.startsWith(mesFiltro)) // Solo vencimientos del mes seleccionado
      .sort((a, b) => new Date(a.fecha) - new Date(b.fecha)); // Ordenar por fecha
  };

  const filtrarFacturasPorMes = () => {
    return facturas
      .filter(factura => 
        factura.vencimientos?.some(v => 
          !v.pagado && v.fecha.startsWith(mesFiltro)
        )
      )
      .sort((a, b) => {
        // Obtener la fecha del primer vencimiento pendiente de cada factura
        const fechaA = a.vencimientos.find(v => !v.pagado && v.fecha.startsWith(mesFiltro))?.fecha || '';
        const fechaB = b.vencimientos.find(v => !v.pagado && v.fecha.startsWith(mesFiltro))?.fecha || '';
        return new Date(fechaA) - new Date(fechaB);
      });
  };

  const cargarDatos = async () => {
    try {
      const [facturasRes, proveedoresRes, formasPagoRes] = await Promise.all([
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/facturas`),
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/proveedores`),
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/fdepagos`)
      ]);

      setFacturas(facturasRes.data);
      setProveedores(proveedoresRes.data);
      setFormasPago(formasPagoRes.data);
    } catch (error) {
      console.error('Error al cargar datos:', error);
      Swal.fire('Error', 'Error al cargar los datos', 'error');
    }
  };

  const calcularVencimientos = (fechaFactura, formaPagoId, importeTotal) => {
    const formaPago = formasPago.find(fp => fp.id === formaPagoId);
    if (!formaPago) return [];

    const vencimientos = [];
    const importePorVencimiento = (importeTotal / formaPago.vencimientos.length).toFixed(2);
    const ultimoImporte = (importeTotal - (importePorVencimiento * (formaPago.vencimientos.length - 1))).toFixed(2);

    formaPago.vencimientos.forEach((venc, index) => {
      const fecha = new Date(fechaFactura);
      fecha.setDate(fecha.getDate() + venc.dias);
      
      vencimientos.push({
        fecha: fecha.toISOString().split('T')[0],
        importe: index === formaPago.vencimientos.length - 1 ? ultimoImporte : importePorVencimiento,
        dias: venc.dias,
        pagado: false
      });
    });

    return vencimientos;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => {
      const newData = { ...prev, [name]: value };
      
      if (name === 'proveedor') {
        const proveedor = proveedores.find(p => p.id === value);
        if (proveedor?.formaPagoDefecto) {
          newData.formaPago = proveedor.formaPagoDefecto;
          if (newData.fechaFactura && newData.importe) {
            const nuevosVencimientos = calcularVencimientos(
              newData.fechaFactura,
              proveedor.formaPagoDefecto,
              newData.importe
            );
            newData.vencimientos = nuevosVencimientos;
            setVencimientosEditables(nuevosVencimientos);
          }
        }
      }
      
      if ((name === 'fechaFactura' || name === 'formaPago' || name === 'importe') && 
          newData.fechaFactura && newData.formaPago && newData.importe) {
        const nuevosVencimientos = calcularVencimientos(
          newData.fechaFactura,
          newData.formaPago,
          newData.importe
        );
        newData.vencimientos = nuevosVencimientos;
        setVencimientosEditables(nuevosVencimientos);
      }
      
      return newData;
    });
  };

  const handleVencimientoChange = (index, campo, valor) => {
    setVencimientosEditables(prev => {
      const nuevosVencimientos = [...prev];
      nuevosVencimientos[index] = {
        ...nuevosVencimientos[index],
        [campo]: valor
      };
      return nuevosVencimientos;
    });

    setFormData(prev => ({
      ...prev,
      vencimientos: vencimientosEditables
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/facturas/${formData.id}`, formData);
        Swal.fire('¡Éxito!', 'Factura actualizada', 'success');
      } else {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/facturas`, formData);
        Swal.fire('¡Éxito!', 'Factura creada', 'success');
      }
      setShowModal(false);
      cargarDatos();
    } catch (error) {
      Swal.fire('Error', error.response?.data?.message || 'Error al guardar', 'error');
    }
  };

  const handlePagarVencimiento = async (facturaId, vencimientoIndex) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/facturas/${facturaId}/vencimientos/${vencimientoIndex}`);
      cargarDatos();
      Swal.fire('¡Éxito!', 'Vencimiento marcado como pagado', 'success');
    } catch (error) {
      Swal.fire('Error', 'No se pudo marcar el vencimiento como pagado', 'error');
    }
  };

  const getNombreProveedor = (proveedorId) => {
    const proveedor = proveedores.find(p => p.id === proveedorId);
    return proveedor ? proveedor.nombre : 'N/A';
  };

  const getFormaPago = (formaPagoId) => {
    const formaPago = formasPago.find(f => f.id === formaPagoId);
    return formaPago ? formaPago.nombre : 'N/A';
  };

  const calcularTotalVencimientos = (vencimientos) => {
    if (!vencimientos || !Array.isArray(vencimientos)) return '0.00';
    return vencimientos
      .reduce((total, v) => total + (parseFloat(v.importe) || 0), 0)
      .toFixed(2);
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: '¿Estás seguro?',
        text: "No podrás revertir esta acción",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/facturas/${id}`);
        Swal.fire('Eliminado', 'La factura ha sido eliminada', 'success');
        cargarDatos();
      }
    } catch (error) {
      console.error('Error al eliminar factura:', error);
      Swal.fire('Error', 'No se pudo eliminar la factura', 'error');
    }
  };

  const getVencimientoStyle = (fechaVencimiento) => {
    const hoy = new Date();
    hoy.setHours(0, 0, 0, 0);
    
    const fechaV = new Date(fechaVencimiento);
    fechaV.setHours(0, 0, 0, 0);

    if (fechaV.getTime() === hoy.getTime()) {
      return 'bg-info bg-opacity-25'; // Azul para vencimientos de hoy
    } else if (fechaV < hoy) {
      return 'bg-danger bg-opacity-25'; // Rojo para vencimientos pasados
    }
    return ''; // Sin estilo para vencimientos futuros
  };

  const MobileCard = ({ factura }) => (
    <div className="card mb-3 shadow-sm">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h6 className="mb-0">#{factura.numeroFactura}</h6>
        <small>{formatDate(factura.fechaFactura)}</small>
      </div>
      <div className="card-body">
        <div className="mb-2">
          <strong>Proveedor:</strong> {getNombreProveedor(factura.proveedor)}
        </div>
        <div className="mb-2">
          <strong>Forma de Pago:</strong> {getFormaPago(factura.formaPago)}
        </div>
        <div className="mb-3">
          <strong>Total:</strong> {calcularTotalVencimientos(factura.vencimientos)}€
        </div>
        
        <div className="mb-3">
          <strong>Vencimientos:</strong>
          {filtrarYOrdenarVencimientos(factura.vencimientos, mesFiltro)
            .map((vencimiento, index) => (
              <div 
                key={index}
                className={`mt-2 p-2 rounded ${!vencimiento.pagado ? getVencimientoStyle(vencimiento.fecha) : ''}`}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <div>{formatDate(vencimiento.fecha)}</div>
                    <div className="fw-bold">{parseFloat(vencimiento.importe).toFixed(2)}€</div>
                  </div>
                  {vencimiento.pagado ? 
                    <span className="badge bg-success">Pagado</span> :
                    <button
                      className="btn btn-outline-success btn-sm"
                      onClick={() => handlePagarVencimiento(factura.id, 
                        factura.vencimientos.findIndex(v => v === vencimiento)
                      )}
                    >
                      Pagar
                    </button>
                  }
                </div>
              </div>
          ))}
        </div>
      </div>
      <div className="card-footer d-flex justify-content-end gap-2">
        <button
          className="btn btn-warning btn-sm"
          onClick={() => {
            setIsEditing(true);
            setFormData(factura);
            setShowModal(true);
          }}
        >
          Editar
        </button>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => handleDelete(factura.id)}
        >
          Eliminar
        </button>
      </div>
    </div>
  );

  return (
    <div className="container mt-4">
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-4">
        <h2 className="mb-3 mb-md-0">Vencimientos</h2>
        <button 
          className="btn btn-primary w-100 w-md-auto" 
          onClick={() => {
            setIsEditing(false);
            setFormData({
              numeroFactura: '',
              proveedor: '',
              importe: '',
              fechaFactura: '',
              formaPago: '',
              vencimientos: []
            });
            setShowModal(true);
          }}
        >
          Nueva Factura
        </button>
      </div>

      <div className="row mb-4">
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <div className="input-group">
            <span className="input-group-text">Mes</span>
            <input
              type="month"
              className="form-control"
              value={mesFiltro}
              onChange={(e) => setMesFiltro(e.target.value)}
            />
          </div>
        </div>
        <div className="col-12 col-md-8">
          <div className="alert alert-info mb-0">
            Total vencimientos pendientes para {new Date(mesFiltro + '-01').toLocaleDateString('es-ES', { month: 'long', year: 'numeric' })}: 
            <strong> {totalVencimientos.toFixed(2)}€</strong>
          </div>
        </div>
      </div>

      {isMobile ? (
        <div className="px-2">
          {filtrarFacturasPorMes().map(factura => (
            <MobileCard key={factura.id} factura={factura} />
          ))}
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Número Factura</th>
                <th>Proveedor</th>
                <th>Fecha Factura</th>
                <th>Forma de Pago</th>
                <th>Importe Total</th>
                <th>Vencimientos del Mes</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filtrarFacturasPorMes().map(factura => (
                <tr key={factura.id}>
                  <td>{factura.numeroFactura}</td>
                  <td>{getNombreProveedor(factura.proveedor)}</td>
                  <td>{formatDate(factura.fechaFactura)}</td>
                  <td>{getFormaPago(factura.formaPago)}</td>
                  <td>{calcularTotalVencimientos(factura.vencimientos)}€</td>
                  <td>
                    <div className="d-flex flex-column">
                      {filtrarYOrdenarVencimientos(factura.vencimientos, mesFiltro)
                        .map((vencimiento, index) => (
                          <div 
                            key={index} 
                            className={`mb-1 p-1 rounded ${!vencimiento.pagado ? getVencimientoStyle(vencimiento.fecha) : ''}`}
                          >
                            <small className={`d-flex align-items-center justify-content-between ${vencimiento.pagado ? 'text-muted' : ''}`}>
                              <span>
                                {formatDate(vencimiento.fecha)} - {parseFloat(vencimiento.importe).toFixed(2)}€
                              </span>
                              {vencimiento.pagado ? 
                                <span className="badge bg-success ms-2">Pagado</span> :
                                <button
                                  className="btn btn-outline-success btn-sm ms-2"
                                  onClick={() => handlePagarVencimiento(factura.id, 
                                    factura.vencimientos.findIndex(v => v === vencimiento)
                                  )}
                                >
                                  Pagar
                                </button>
                              }
                            </small>
                          </div>
                      ))}
                    </div>
                  </td>
                  <td>
                    <button
                      className="btn btn-warning btn-sm me-2"
                      onClick={() => {
                        setIsEditing(true);
                        setFormData(factura);
                        setShowModal(true);
                      }}
                    >
                      Editar
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDelete(factura.id)}
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Modal para nueva/editar factura */}
      {showModal && (
        <div className="modal fade show d-block" tabIndex="-1">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {isEditing ? 'Editar' : 'Nueva'} Factura
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Número de Factura</label>
                      <input
                        type="text"
                        className="form-control"
                        name="numeroFactura"
                        value={formData.numeroFactura}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Proveedor</label>
                      <select
                        className="form-select"
                        name="proveedor"
                        value={formData.proveedor}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Seleccione proveedor</option>
                        {proveedores.map(prov => (
                          <option key={prov.id} value={prov.id}>
                            {prov.nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label className="form-label">Importe</label>
                      <input
                        type="number"
                        className="form-control"
                        name="importe"
                        value={formData.importe}
                        onChange={handleChange}
                        step="0.01"
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="form-label">Fecha Factura</label>
                      <input
                        type="date"
                        className="form-control"
                        name="fechaFactura"
                        value={formData.fechaFactura}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="form-label">Forma de Pago</label>
                      <select
                        className="form-select"
                        name="formaPago"
                        value={formData.formaPago}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Seleccione forma de pago</option>
                        {formasPago.map(forma => (
                          <option key={forma.id} value={forma.id}>
                            {forma.nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {vencimientosEditables.length > 0 && (
                    <div className="mt-4">
                      <h6>Vencimientos</h6>
                      <div className="table-responsive">
                        <table className="table table-sm">
                          <thead>
                            <tr>
                              <th>Fecha</th>
                              <th>Días</th>
                              <th>Importe</th>
                            </tr>
                          </thead>
                          <tbody>
                            {vencimientosEditables.map((venc, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    value={venc.fecha}
                                    onChange={(e) => handleVencimientoChange(index, 'fecha', e.target.value)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control form-control-sm"
                                    value={venc.dias}
                                    onChange={(e) => handleVencimientoChange(index, 'dias', parseInt(e.target.value))}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control form-control-sm"
                                    value={venc.importe}
                                    onChange={(e) => handleVencimientoChange(index, 'importe', parseFloat(e.target.value))}
                                    step="0.01"
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="2" className="text-end">Total:</td>
                              <td>
                                {vencimientosEditables.reduce((sum, v) => sum + parseFloat(v.importe), 0).toFixed(2)}€
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Guardar
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TableComponent;
