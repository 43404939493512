import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const user = JSON.parse(localStorage.getItem('user'));

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const NavLink = ({ to, children }) => (
    <Link 
      to={to} 
      className={`nav-link px-3 d-flex align-items-center ${isActive(to)}`}
      onClick={() => setIsOpen(false)}
    >
      {children}
    </Link>
  );

  return (
    <nav className="navbar navbar-expand-md navbar-dark shadow-sm sticky-top">
      <div className="container-fluid px-3">
        <Link to="/" className="navbar-brand d-flex align-items-center">
          <i className="bi bi-calendar-check fs-4 me-2"></i>
          <span className="fw-bold">APPVenci</span>
        </Link>

        <button
          className="navbar-toggler border-0"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}>
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            <li className="nav-item">
              <NavLink to="/">
                <i className="bi bi-table fs-5 me-2"></i>
                Vencimientos
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/proveedores">
                <i className="bi bi-building fs-5 me-2"></i>
                Proveedores
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/fdepagos">
                <i className="bi bi-cash-coin fs-5 me-2"></i>
                Formas de Pago
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/facturaspagadas">
                <i className="bi bi-file-earmark-check fs-5 me-2"></i>
                Facturas Pagadas
              </NavLink>
            </li>
          </ul>

          <div className={`d-${isMobile ? 'grid' : 'flex'} align-items-center gap-3`}>
            <Link 
              to="/usuarios" 
              className={`text-light text-decoration-none d-flex align-items-center user-link ${isActive('/usuarios')}`}
            >
              <i className="bi bi-person-circle fs-5 me-2"></i>
              <span>{user?.nombre || user?.username}</span>
            </Link>
            <button
              onClick={handleLogout}
              className="btn btn-outline-light d-flex align-items-center gap-2"
            >
              <i className="bi bi-power"></i>
              <span>Cerrar Sesión</span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;