import React from 'react';
import { createRoot } from 'react-dom/client';
import { 
  Route, 
  Navigate,
  createRoutesFromElements
} from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Barra from './components/NavBar';
import TableComponent from './components/Table';
import FacturasPagadas from './components/FacturasPagadas';
import Proveedores from './components/Proveedores';
import FdePagos from './components/FormasDePago';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import Usuarios from './components/Usuarios';
import 'bootstrap-icons/font/bootstrap-icons.css';

const container = document.getElementById('root');
const root = createRoot(container);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/login" element={<Login />} />
      
      {/* Rutas protegidas */}
      <Route path="/" element={
        <PrivateRoute>
          <>
            <Barra />
            <TableComponent />
          </>
        </PrivateRoute>
      } />
      
      <Route path="/facturaspagadas" element={
        <PrivateRoute>
          <>
            <Barra />
            <FacturasPagadas />
          </>
        </PrivateRoute>
      } />
      
      <Route path="/proveedores" element={
        <PrivateRoute>
          <>
            <Barra />
            <Proveedores />
          </>
        </PrivateRoute>
      } />
      
      <Route path="/fdepagos" element={
        <PrivateRoute>
          <>
            <Barra />
            <FdePagos />
          </>
        </PrivateRoute>
      } />

      <Route path="/usuarios" element={
        <PrivateRoute>
          <>
            <Barra />
            <Usuarios />
          </>
        </PrivateRoute>
      } />

      {/* Ruta por defecto - redirige a login si no está autenticado */}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Route>
  ),
  {
    future: {
      v7_relativeSplatPath: true
    }
  }
);

root.render(
  <RouterProvider router={router} />
);