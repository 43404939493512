import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import axios from '../utils/axios';
import Swal from 'sweetalert2';

function FacturasPagadas() {
  const [facturasPagadas, setFacturasPagadas] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [formasPago, setFormasPago] = useState([]);
  const [mesFiltro, setMesFiltro] = useState(new Date().toISOString().slice(0, 7));
  
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    cargarDatos();
  }, []);

  const cargarDatos = async () => {
    try {
      const [facturasRes, proveedoresRes, formasPagoRes] = await Promise.all([
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/facturaspagadas`),
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/proveedores`),
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/fdepagos`)
      ]);

      setFacturasPagadas(facturasRes.data);
      setProveedores(proveedoresRes.data);
      setFormasPago(formasPagoRes.data);
    } catch (error) {
      console.error('Error al cargar datos:', error);
      Swal.fire('Error', 'Error al cargar los datos', 'error');
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const getNombreProveedor = (proveedorId) => {
    const proveedor = proveedores.find(p => p.id === proveedorId);
    return proveedor ? proveedor.nombre : 'N/A';
  };

  const getFormaPago = (formaPagoId) => {
    const formaPago = formasPago.find(f => f.id === formaPagoId);
    return formaPago ? formaPago.nombre : 'N/A';
  };

  const calcularTotalVencimientos = (vencimientos) => {
    if (!vencimientos || !Array.isArray(vencimientos)) return '0.00';
    return vencimientos
      .reduce((total, v) => total + (parseFloat(v.importe) || 0), 0)
      .toFixed(2);
  };

  const filtrarFacturasPorMes = () => {
    return facturasPagadas.filter(factura => 
      factura.fechaPago?.startsWith(mesFiltro)
    );
  };

  const MobileCard = ({ factura }) => (
    <div className="card mb-3 shadow-sm">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h6 className="mb-0">#{factura.numeroFactura}</h6>
        <small className="text-muted">Pagada: {formatDate(factura.fechaPago)}</small>
      </div>
      <div className="card-body">
        <div className="row mb-2">
          <div className="col-6">
            <strong>Proveedor:</strong>
            <div>{getNombreProveedor(factura.proveedor)}</div>
          </div>
          <div className="col-6">
            <strong>Fecha Factura:</strong>
            <div>{formatDate(factura.fechaFactura)}</div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <strong>Forma de Pago:</strong>
            <div>{getFormaPago(factura.formaPago)}</div>
          </div>
          <div className="col-6">
            <strong>Total:</strong>
            <div>{calcularTotalVencimientos(factura.vencimientos)}€</div>
          </div>
        </div>
        <div className="mt-3">
          <strong>Vencimientos:</strong>
          {factura.vencimientos?.map((venc, index) => (
            <div key={index} className="border-bottom py-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>{formatDate(venc.fecha)}</div>
                <div>{parseFloat(venc.importe).toFixed(2)}€</div>
              </div>
              <small className="text-success">
                Pagado el {formatDate(venc.fechaPago)}
              </small>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="container mt-4">
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-4">
        <h2 className="mb-3 mb-md-0">Facturas Pagadas</h2>
        <div className="w-100 w-md-auto">
          <div className="input-group">
            <span className="input-group-text">Mes</span>
            <input
              type="month"
              className="form-control"
              value={mesFiltro}
              onChange={(e) => setMesFiltro(e.target.value)}
            />
          </div>
        </div>
      </div>

      {isMobile ? (
        <div className="px-2">
          {filtrarFacturasPorMes().map(factura => (
            <MobileCard key={factura.id} factura={factura} />
          ))}
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Número Factura</th>
                <th>Proveedor</th>
                <th>Fecha Factura</th>
                <th>Forma de Pago</th>
                <th>Importe Total</th>
                <th>Fecha Pago</th>
                <th>Vencimientos</th>
              </tr>
            </thead>
            <tbody>
              {filtrarFacturasPorMes().map(factura => (
                <tr key={factura.id}>
                  <td>{factura.numeroFactura}</td>
                  <td>{getNombreProveedor(factura.proveedor)}</td>
                  <td>{formatDate(factura.fechaFactura)}</td>
                  <td>{getFormaPago(factura.formaPago)}</td>
                  <td>{calcularTotalVencimientos(factura.vencimientos)}€</td>
                  <td>{formatDate(factura.fechaPago)}</td>
                  <td>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => {
                        Swal.fire({
                          title: 'Detalle de Vencimientos',
                          html: `
                            <div class="table-responsive">
                              <table class="table table-sm">
                                <thead>
                                  <tr>
                                    <th>Fecha</th>
                                    <th>Importe</th>
                                    <th>Fecha Pago</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  ${factura.vencimientos?.map(v => `
                                    <tr>
                                      <td>${formatDate(v.fecha)}</td>
                                      <td>${parseFloat(v.importe).toFixed(2)}€</td>
                                      <td>${formatDate(v.fechaPago)}</td>
                                    </tr>
                                  `).join('')}
                                </tbody>
                              </table>
                            </div>
                          `,
                          width: '600px'
                        });
                      }}
                    >
                      Ver Vencimientos
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default FacturasPagadas;