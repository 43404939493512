import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import axios from '../utils/axios';
import Swal from 'sweetalert2';

function FdePagos() {
  const [formasPago, setFormasPago] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    nombre: '',
    tieneMultiplesVencimientos: false,
    vencimientos: [{ dias: 0 }]
  });
  const [isEditing, setIsEditing] = useState(false);
  
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    cargarFormasPago();
  }, []);

  const cargarFormasPago = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/fdepagos`);
      const formasPagoConVencimientos = response.data.map(forma => ({
        ...forma,
        vencimientos: forma.vencimientos || [{ dias: 0 }]
      }));
      setFormasPago(formasPagoConVencimientos);
    } catch (error) {
      console.error('Error al cargar formas de pago:', error);
      Swal.fire('Error', 'No se pudieron cargar las formas de pago', 'error');
    }
  };

  const handleChange = (e) => {
    const { name, checked, value, type } = e.target;
    if (name === 'tieneMultiplesVencimientos') {
      setFormData(prev => ({
        ...prev,
        [name]: checked,
        vencimientos: checked ? [{ dias: 0 }] : [{ dias: 0 }]
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleVencimientoChange = (index, value) => {
    setFormData(prev => {
      const newVencimientos = [...prev.vencimientos];
      newVencimientos[index] = { dias: parseInt(value) };
      return {
        ...prev,
        vencimientos: newVencimientos
      };
    });
  };

  const addVencimiento = () => {
    setFormData(prev => ({
      ...prev,
      vencimientos: [...prev.vencimientos, { dias: 0 }]
    }));
  };

  const removeVencimiento = (index) => {
    setFormData(prev => ({
      ...prev,
      vencimientos: prev.vencimientos.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Validar que los días estén en orden ascendente
      const diasOrdenados = [...formData.vencimientos].sort((a, b) => a.dias - b.dias);
      const sonIguales = formData.vencimientos.every((v, i) => v.dias === diasOrdenados[i].dias);
      
      if (!sonIguales) {
        Swal.fire('Error', 'Los días de vencimiento deben estar en orden ascendente', 'error');
        return;
      }

      if (isEditing) {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/fdepagos/${formData.id}`, formData);
        Swal.fire('¡Éxito!', 'Forma de pago actualizada', 'success');
      } else {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/fdepagos`, formData);
        Swal.fire('¡Éxito!', 'Forma de pago creada', 'success');
      }
      setShowModal(false);
      cargarFormasPago();
    } catch (error) {
      Swal.fire('Error', error.response?.data?.message || 'Error al guardar', 'error');
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "Esta acción no se puede deshacer",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/fdepagos/${id}`);
        Swal.fire('Eliminado', 'La forma de pago ha sido eliminada', 'success');
        cargarFormasPago();
      } catch (error) {
        Swal.fire('Error', 'No se pudo eliminar la forma de pago', 'error');
      }
    }
  };

  const MobileCard = ({ forma }) => (
    <div className="card mb-3 shadow-sm">
      <div className="card-header">
        <h6 className="mb-0">{forma.nombre}</h6>
      </div>
      <div className="card-body">
        <div className="mb-2">
          <strong>Vencimientos:</strong>
          <div className="mt-1">
            {forma.vencimientos?.map((v, i) => (
              <span key={i} className="badge bg-secondary me-2 mb-1">
                {v.dias} días
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="card-footer d-flex justify-content-end gap-2">
        <button
          className="btn btn-warning btn-sm"
          onClick={() => {
            setIsEditing(true);
            setFormData(forma);
            setShowModal(true);
          }}
        >
          Editar
        </button>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => handleDelete(forma.id)}
        >
          Eliminar
        </button>
      </div>
    </div>
  );

  return (
    <div className="container mt-4">
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-4">
        <h2 className="mb-3 mb-md-0">Formas de Pago</h2>
        <button 
          className="btn btn-primary w-100 w-md-auto"
          onClick={() => {
            setIsEditing(false);
            setFormData({
              nombre: '',
              tieneMultiplesVencimientos: false,
              vencimientos: [{ dias: 0 }]
            });
            setShowModal(true);
          }}
        >
          Nueva Forma de Pago
        </button>
      </div>

      {isMobile ? (
        <div className="px-2">
          {formasPago.map(forma => (
            <MobileCard key={forma.id} forma={forma} />
          ))}
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Vencimientos (días)</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {formasPago.map(forma => (
                <tr key={forma.id}>
                  <td>{forma.nombre}</td>
                  <td>
                    {forma.vencimientos?.map((v, i) => (
                      <span key={i} className="badge bg-secondary me-2">
                        {v.dias} días
                      </span>
                    ))}
                  </td>
                  <td>
                    <button
                      className="btn btn-warning btn-sm me-2"
                      onClick={() => {
                        setIsEditing(true);
                        setFormData(forma);
                        setShowModal(true);
                      }}
                    >
                      Editar
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDelete(forma.id)}
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showModal && (
        <div className="modal fade show d-block" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {isEditing ? 'Editar' : 'Nueva'} Forma de Pago
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nombre"
                      value={formData.nombre}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="tieneMultiplesVencimientos"
                        checked={formData.tieneMultiplesVencimientos}
                        onChange={handleChange}
                        id="multipleVencimientos"
                      />
                      <label className="form-check-label" htmlFor="multipleVencimientos">
                        Tiene múltiples vencimientos
                      </label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Vencimientos</label>
                    {formData.vencimientos.map((vencimiento, index) => (
                      <div key={index} className="d-flex align-items-center gap-2 mb-2">
                        <input
                          type="number"
                          className="form-control"
                          value={vencimiento.dias}
                          onChange={(e) => handleVencimientoChange(index, e.target.value)}
                          min="0"
                          required
                        />
                        <span>días</span>
                        {formData.tieneMultiplesVencimientos && (
                          <>
                            {index > 0 && (
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => removeVencimiento(index)}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            )}
                            {index === formData.vencimientos.length - 1 && (
                              <button
                                type="button"
                                className="btn btn-success btn-sm"
                                onClick={addVencimiento}
                              >
                                <i className="bi bi-plus"></i>
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Guardar
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FdePagos;
